if (![].at) {
  Array.prototype.at = function (pos) {
    if (pos < 0) {
      pos = this.length + pos;
    }
    return this.slice(pos, pos + 1)[0];
  };
}

export {};
